import React from "react"
import Layout from "../components/layout" // Ensure you have a Layout component
import Seo from "../components/seo" // Ensure you have an SEO component

const EventsPage = () => {
  return (
    <Layout>
      <Seo title="Events" />
      <div className="bg-gameOnLighterGreen py-10">
        
      <div className="text-center">
          <h1 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">
          Upcoming Events
          </h1>
          <p className="text-lg font-semibold mb-2 text-gameOnGreen my-5">
          When the game stops, it's not game over, but Game On!
          </p>
      </div>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-5 px-5 lg:px-0 py-10">

        {/* Sidebar for events */}
        <div className="col-span-1 mb-10 lg:mb-0 bg-gameOnDarkGreen rounded-md p-5">
          <h2 className="text-3xl font-bold mb-5 text-white">Events</h2>
          <ul className="space-y-2 text-lg">
            <li className="bg-gameOnDarkGreenActive p-2 rounded-md text-gray-400">June 2023</li>
            <li className="bg-gameOnGreen p-2 rounded-md text-white">July 2023</li>
            <li className="bg-gameOnDarkGreenActive p-2 rounded-md text-gray-400">August 2023</li>
          </ul>
        </div>

        {/* Main content */}
        <div className="col-span-4 lg:px-8">
          <div className="bg-gameOnLightGreen p-10 rounded-3xl shadow-lg">
            <h1 className="text-gameOnDarkBlue w-full bg-gameOnDarkGreenActive text-center text-white py-3 rounded-3xl text-3xl font-bold mb-4">Game On! Live Stream</h1>
            {/* Event details here */}
            <div className="space-y-3 border border-gameOnGreenActive text-center p-5 rounded-3xl">
              <p><strong>Date:</strong> July 1, 2023</p>
              <p><strong>Time:</strong> 1pm (PST)</p>
              <p><strong>Event Type:</strong> Livestream</p>
              <p><strong>Location:</strong> @thegameonapp</p>
              <br />
              <button
                className="inline-block px-14 w-full lg:w-auto text-center py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent bg-gameOnGreen hover:bg-gameOnLightGreenActive lg:mt-0 "
              >
                Join Event
              </button>
            </div>
            

          </div>
        </div>

        </div>
      </div>

      </div>
    </Layout>
  )
}

export default EventsPage